<template>
  <FocusBar defaultClosePath="/customer/project/progress" hide-save-buttons>
    <template #middle>
      <div v-if="!smallFormat" class="flex justify-center items-center w-full h-16">
        <h4>{{ quoteName }}</h4>
      </div>
      <div v-else>Your interactive quote</div>
    </template>
  </FocusBar>
  <QuotePresentation :trackChanges="false" :id="quoteId" />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import QuotePresentation from '@/components/quote/presentation/QuotePresentation.vue'
import FocusBar from '@/components/layout/header/FocusBar.vue'
import { useMediaQuery } from '@/composables/mediaQuery'
const { smallFormat } = useMediaQuery()

const store = useStore()
const quoteId = computed(() => store.state.session.quote.quote_id)
const quoteName = computed(() => store.state.session.quote.quote_name)
</script>
